import React, { useRef } from "react";
import classes from './FeelConfident.module.scss';
import { Trans, useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import './Swiper.scss';
import card_bg_01 from '../../../assets/img/TradingAccountsPage/FeelConfident/card_bg_01.png';
import card_bg_02 from '../../../assets/img/TradingAccountsPage/FeelConfident/card_bg_02.png';
import card_bg_03 from '../../../assets/img/TradingAccountsPage/FeelConfident/card_bg_03.png';
import card_bg_04 from '../../../assets/img/TradingAccountsPage/FeelConfident/card_bg_04.png';
import Button from '../../../components/GlobalComponents/Button/Button';

const cards = [
    {
        id: 1,
        image: card_bg_01,
        title: 'feel_confident_card_01_title',
        buttons: [
            'feel_confident_card_01_button',
        ],
        text: 'feel_confident_card_01_text',
        row_01: 'feel_confident_card_01_row_01',
        row_02: 'feel_confident_card_01_row_02',
        row_03: 'feel_confident_card_01_row_03',
        row_04: [
            'feel_confident_card_01_row_04_01',
            'feel_confident_card_01_row_04_02',
            'feel_confident_card_01_row_04_03',
            'feel_confident_card_01_row_04_04',
            'feel_confident_card_01_row_04_05',
        ],
    },
    {
        id: 2,
        image: card_bg_02,
        title: 'feel_confident_card_02_title',
        buttons: [
            'feel_confident_card_02_button_01',
            'feel_confident_card_02_button_02',
        ],
        text: 'feel_confident_card_02_text',
        row_01: 'feel_confident_card_02_row_01',
        row_02: 'feel_confident_card_02_row_02',
        row_03: 'feel_confident_card_02_row_03',
        row_04: [
            'feel_confident_card_01_row_04_01',
            'feel_confident_card_01_row_04_02',
            'feel_confident_card_01_row_04_03',
            'feel_confident_card_01_row_04_04',
            'feel_confident_card_01_row_04_05',
        ],
    },
    {
        id: 3,
        image: card_bg_03,
        title: 'feel_confident_card_03_title',
        buttons: [
            'feel_confident_card_03_button',
        ],
        text: 'feel_confident_card_03_text',
        row_01: 'feel_confident_card_03_row_01',
        row_02: 'feel_confident_card_03_row_02',
        row_03: 'feel_confident_card_03_row_03',
        row_04: [
            'feel_confident_card_03_row_04_01',
            'feel_confident_card_03_row_04_02',
            'feel_confident_card_03_row_04_03',
        ],
    },
    {
        id: 4,
        image: card_bg_04,
        title: 'feel_confident_card_04_title',
        buttons: [
            'feel_confident_card_04_button',
        ],
        text: 'feel_confident_card_04_text',
        row_01: 'feel_confident_card_04_row_01',
        row_02: 'feel_confident_card_04_row_02',
        row_03: 'feel_confident_card_04_row_03',
        row_04: [
            'feel_confident_card_04_row_04_01',
            'feel_confident_card_04_row_04_02',
        ],
    },
];

const FeelConfident = () => {
    const { t } = useTranslation();
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    return (
        <section className={classes.feelConfident}>
            <div className="container">
                <h2 className={[classes.title, 'font-48'].join(' ')}>
                    {t('feel_confident_title')}
                </h2>
            </div>
            <div className={classes.cards}>
                <Swiper 
                    modules={[Navigation, Pagination]}
                    className="feelConfidentSwiper"
                    spaceBetween={30}
                    slidesPerView={"auto"}
                    navigation={{
                        prevEl: prevRef.current,
                        nextEl: nextRef.current,
                    }}
                    pagination={true}
                >
                    {cards.map((card) =>
                        <SwiperSlide className="feelConfidentSwiperSlide" key={card.id}>
                            <div className={classes.card}>
                                <img className={classes.cardImage} src={card.image} alt={card.title}/>
                                <div className={classes.cardContent}>
                                    <div className={classes.cardHeader}>
                                        <h3 className={classes.cardTitle}>
                                            {t(card.title)}
                                        </h3>
                                        <div className={classes.cardButtons}>
                                            {card.buttons.map((btn, btnIndex) =>
                                                <div key={btnIndex} className={classes.cardButtonsBtn}>
                                                    {t(btn)}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <p className={classes.cardText}>
                                        <Trans>
                                            {t(card.text)}
                                        </Trans>
                                    </p>
                                    <div className={classes.cardTable}>
                                        <div className={classes.cardTableRow}>
                                            <div className={classes.cardTableRowLeft}>
                                                {t('feel_confident_table_row_01')}
                                            </div>
                                            <div className={classes.cardTableRowRight}>
                                                {t(card.row_01)}
                                            </div>
                                        </div>
                                        <div className={classes.cardTableRow}>
                                            <div className={classes.cardTableRowLeft}>
                                                {t('feel_confident_table_row_02')}
                                            </div>
                                            <div className={classes.cardTableRowRight}>
                                                {t(card.row_02)}
                                            </div>
                                        </div>
                                        <div className={classes.cardTableRow}>
                                            <div className={classes.cardTableRowLeft}>
                                                {t('feel_confident_table_row_03')}
                                            </div>
                                            <div className={classes.cardTableRowRight}>
                                                {t(card.row_03)}
                                            </div>
                                        </div>
                                        <div className={classes.cardTableRow}>
                                            <div className={classes.cardTableRowLeft}>
                                                {t('feel_confident_table_row_04')}
                                            </div>
                                            <div className={classes.cardTableRowRight}>
                                                {card.row_04.map((row, rowIndex) =>
                                                    <p key={rowIndex}>
                                                        {t(row)}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.cardBtn}>
                                    <Button>{t('btn_open_account')}</Button>
                                </div>
                            </div>
                        </SwiperSlide>
                    )}
                </Swiper>
            </div>
            <div className="container">
                <div className={classes.pagination}>
                    <div ref={prevRef} className={[classes.paginationLeft, classes.paginationItem].join(' ')}>
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="#02A69E"/>
                            <path d="M17 28L25 20.2727L17 13" stroke="#02A69E"/>
                        </svg>
                    </div>
                    <div ref={nextRef} className={[classes.paginationRight, classes.paginationItem].join(' ')}>
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="#02A69E"/>
                            <path d="M17 28L25 20.2727L17 13" stroke="#02A69E"/>
                        </svg>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FeelConfident;
