import React, { useEffect, useRef, useState } from "react";
import classes from "./Most.module.scss";
import { Trans, useTranslation } from "react-i18next";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Most = () => {
  const { t } = useTranslation();
  const mainRef = useRef(null);
  useEffect(() => {
    gsap.fromTo(
      mainRef.current,
      {
        opacity: 0,
        y: 150,
      },
      {
        opacity: 1,
        y: 0,
        delay: 0.5,
        duration: 1,
        scrollTrigger: {
          trigger: mainRef.current,
          start: "top bottom",
          end: "top center",
          scrub: 5,
          id: "scrub",
        },
      }
    );
  }, []);
  const [spoilers, setSpoilers] = useState([
    {
      id: 1,
      title: "education_faq_01_title",
      active: false,
      contents: [
        {
          id: 1,
          isStart: false,
          listNumbers: [],
          text: "education_faq_01_text",
        },
      ],
    },
    {
      id: 2,
      title: "education_faq_02_title",
      active: false,
      contents: [
        {
          id: 1,
          isStart: true,
          text: `education_faq_02_text`,
        },
      ],
    },
    {
      id: 3,
      title: "education_faq_03_title",
      active: false,
      contents: [
        {
          id: 1,
          isStart: true,
          text: `education_faq_03_text`,
        },
      ],
    },
    {
      id: 4,
      title: "education_faq_04_title",
      active: false,
      contents: [
        {
          id: 1,
          isStart: true,
          text: `education_faq_04_text`,
        },
      ],
    },
    {
      id: 5,
      title: "education_faq_05_title",
      active: false,
      contents: [
        {
          id: 1,
          isStart: true,
          text: `education_faq_05_text`,
          listNumbers: [],
        },
      ],
    },
    {
      id: 6,
      title: "education_faq_06_title",
      active: false,
      contents: [
        {
          id: 1,
          isStart: true,
          text: `education_faq_06_text`,
          listNumbers: [],
        },
      ],
    },
  ]);

  return (
    <div className={classes.most} ref={mainRef}>
      <div className={classes.mostBody}>
        <div className="container">
          <h2 className={[classes.mostTitle, "font-48"].join(" ")}>
            {t('education_faq_title')}
          </h2>

          {spoilers.map((i) => {
            return (
              <div key={i.id} className={classes.mostSpoiler}>
                <p
                  className={
                    i.active
                      ? [
                          classes.mostSpoilerTitle,
                          "font-24",
                          classes.active,
                        ].join(" ")
                      : [classes.mostSpoilerTitle, "font-24"].join(" ")
                  }
                  onClick={() => {
                    setSpoilers(
                      spoilers.map((curI) => {
                        if (curI.id === i.id) {
                          return { ...curI, active: !curI.active };
                        }
                        return { ...curI, active: false };
                      })
                    );
                  }}
                >
                  <Trans>{t(i.title)}</Trans>
                </p>
                {i.contents.map((subI) => {
                  return (
                    <div
                      key={subI.id}
                      className={
                        i.active
                          ? subI.isStart
                            ? [
                                classes.mostSpoilerContent,
                                classes.active,
                                classes.star,
                                "font-20",
                              ].join(" ")
                            : [
                                classes.mostSpoilerContent,
                                classes.active,
                                "font-20",
                              ].join(" ")
                          : classes.mostSpoilerContent
                      }
                    >
                      {t(subI.text)}
                      {subI.listNumbers && (
                        <ol>
                          {subI.listNumbers.map((listNum) => {
                            return <li className="font-20">{t(listNum)}</li>;
                          })}
                        </ol>
                      )}
                      {subI.listCircle && (
                        <ul style={{ listStyleType: "circle" }}>
                          {subI.listCircle.map((listNum) => {
                            return <li className="font-20">{t(listNum)}</li>;
                          })}
                        </ul>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Most;
