import logo from '../assets/img/logo.png';
// main page
import main_banner_bg from '../assets/img/MainPage/Banner/banner_bg.png';
import main_banner_bg_mob from '../assets/img/MainPage/Banner/banner_bg_mob.png';
import main_underbanner_icon_01 from '../assets/img/MainPage/UnderBanner/icon_01.svg';
import main_underbanner_icon_02 from '../assets/img/MainPage/UnderBanner/icon_02.svg';
import main_underbanner_icon_03 from '../assets/img/MainPage/UnderBanner/icon_03.svg';
import main_underbanner_icon_04 from '../assets/img/MainPage/UnderBanner/icon_04.svg';
// start page
import start_bg_01 from '../assets/img/StartPage/1/1.png';
import start_bg_02 from '../assets/img/StartPage/1/11.png';
import start_bg_03 from '../assets/img/StartPage/1/111.png';
import start_bg_04 from '../assets/img/StartPage/1/1111.png';
// security
import security_bg_01 from '../assets/img/SecurityPage/Banner/bg_top.png';
import security_bg_02 from '../assets/img/SecurityPage/Banner/bg_bottom.png';
import security_bg_03 from '../assets/img/SecurityPage/Banner/bg_top_mob.png';
// trading accounts
import trading_bg_01 from '../assets/img/TradingAccountsPage/Banner/bg.png';
import trading_bg_02 from '../assets/img/TradingAccountsPage/Banner/bg_mob.png';
// education
import edu_bg_01 from '../assets/img/EducationPage/1/1.png';
import edu_bg_02 from '../assets/img/EducationPage/1/11.png';
import edu_bg_03 from '../assets/img/EducationPage/1/111.png';
import edu_bg_04 from '../assets/img/EducationPage/1/1111.png';
// about
import about_bg_01 from '../assets/img/AboutPage/1/1.png';
import about_bg_02 from '../assets/img/AboutPage/1/11.png';

export const images = [
    logo,
    main_banner_bg,
    main_banner_bg_mob,
    main_underbanner_icon_01,
    main_underbanner_icon_02,
    main_underbanner_icon_03,
    main_underbanner_icon_04,
    start_bg_01,
    start_bg_02,
    start_bg_03,
    start_bg_04,
    security_bg_01,
    security_bg_02,
    security_bg_03,
    trading_bg_01,
    trading_bg_02,
    edu_bg_01,
    edu_bg_02,
    edu_bg_03,
    edu_bg_04,
    about_bg_01,
    about_bg_02,
];
