import React, { useEffect, useState } from "react";
import classes from "./Header.module.scss";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import i18n from "i18next";
import Cookies from "js-cookie";
import { headerRoutes } from "../../../router";
import Button from "../Button/Button";
import $ from "jquery";
import logo from "../../../assets/img/logo.png";

const languages = [
  { value: "ru", label: "RU" },
  { value: "en", label: "EN" },
  { value: "es", label: "ES" },
  { value: "it", label: "IT" },
  { value: "de", label: "DE" },
  { value: "pl", label: "PL" },
];

const Header = () => {
  const { t } = useTranslation();
  const [burgerOpened, setBurgerOpened] = useState(false);
  const [currentLang, setCurrentLang] = useState(Cookies.get("i18next"));
  const { pathname } = useLocation();
  const lang = Cookies.get('i18next');
  useEffect(() => {
    setCurrentLang(Cookies.get("i18next"));
    if ($("body").hasClass("lock")) {
      $("body").removeClass("lock");
    }
  }, []);
  useEffect(() => {
    if (burgerOpened) {
      $("body").addClass("lock");
    } else {
      $("body").removeClass("lock");
    }
  }, [burgerOpened]);
  useEffect(() => {
    setBurgerOpened(false);
  }, [pathname]);
  window.addEventListener("resize", (event) => {
    setBurgerOpened(false);
  });

  return (
    <div
      className={`${classes.header} ${burgerOpened && classes.headerActive}`}
    >
      <div className={["container", classes.container].join(" ")}>
        <div className={classes.headerRow}>
          <div className={classes.headerRowLogo}>
            <Link to="/" onClick={() => setBurgerOpened(false)}>
              <img src={logo} alt="" />
            </Link>
          </div>
          <div className={classes.headerRowLinks}>
            {headerRoutes.map((route) => (
              <Link
                key={route.id}
                to={route.path}
                className={`${route.path === pathname && classes.linkActive}`}
              >
                {t(route.text)}
              </Link>
            ))}
          </div>
          <div className={classes.headerRowRight}>
            <div className={classes.langs}>
              <Dropdown
                options={languages}
                onChange={(e) => i18n.changeLanguage(e.value)}
                value={currentLang}
                placeholder="EN"
              />
            </div>
            <a href={`https://user.gainground.ltd/login?lang=${lang}`} className={classes.login}>
              {t('btn_login')}
            </a>
            <div
              onClick={() => setBurgerOpened((value) => !value)}
              className={`${classes.burger} ${
                burgerOpened && classes.burgerActive
              }`}
            ></div>
          </div>
        </div>
      </div>
      <div
        className={`${classes.mobileMenu} ${
          burgerOpened && classes.mobileMenuActive
        }`}
      >
        <div className="container">
          {headerRoutes.map((route) => (
            <Link
              key={route.id}
              to={route.path}
              className={`${route.path === pathname && classes.linkActive} ${
                classes.aLink
              }`}
              onClick={() => setBurgerOpened(false)}
            >
              {t(route.text)}
            </Link>
          ))}
          <div className={classes.btn}>
            <Button login="true">{t('btn_login')}</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
