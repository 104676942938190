import React, { useEffect, useRef } from "react";
import Button from "../../GlobalComponents/Button/Button";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Banner = () => {
    const { t } = useTranslation();
    const bannerBodyRef = useRef(null);
    useEffect(() => {
        gsap.fromTo(bannerBodyRef.current, {
            opacity: 0,
            y: 150, 
        }, {
            opacity: 1,
            y: 0,
            delay: .5,
            duration: 1,
        });
    }, []);
    return (
        <section className={classes.banner}>
            <div className="container">
                <div ref={bannerBodyRef} className={classes.bannerBody}>
                    <h1 className={classes.header}>
                        <span className={["font-64", classes.title].join(' ')}>
                            {t('home_banner_title_01')}
                        </span>
                        &nbsp;&nbsp;
                        <span className={["font-48", classes.subtitle].join(' ')}>
                            {t('home_banner_title_02')}
                        </span>
                    </h1>
                    <p className={classes.text}>
                        {t('home_banner_text')}
                    </p>
                    <Button>{t('btn_start_trading')}</Button>
                </div>
            </div>
        </section>
    );
};

export default Banner;
