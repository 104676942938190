import React from "react";
import classes from './FeaturesOf.module.scss';
import icon_01 from '../../../assets/img/MainPage/FeaturesOf/icon_01.svg';
import icon_02 from '../../../assets/img/MainPage/FeaturesOf/icon_02.svg';
import icon_03 from '../../../assets/img/MainPage/FeaturesOf/icon_03.svg';
import icon_04 from '../../../assets/img/MainPage/FeaturesOf/icon_04.svg';
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";

const cards = [
    {
        icon: icon_01,
        text: 'features_of_card_01',
    },
    {
        icon: icon_02,
        text: 'features_of_card_02',
    },
    {
        icon: icon_03,
        text: 'features_of_card_03',
    },
    {
        icon: icon_04,
        text: 'features_of_card_04',
    },
];

const FeaturesOf = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.featuresOf}>
                <h2 className={[classes.title, 'font-64'].join(' ')}>
                    {t('features_of_title')}
                </h2>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <div className={classes.card} key={index}>
                            <img className={classes.cardIcon} src={card.icon} alt=''/>
                            <p className={classes.cardText}>
                                <Trans>
                                    {t(card.text)}
                                </Trans>
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default FeaturesOf;
