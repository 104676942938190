import React, { useEffect, useRef } from "react";
import classes from "./Second.module.scss";
import img1 from "../../../assets/img/StartPage/2/1.png";
import img2 from "../../../assets/img/StartPage/2/2.png";
import img3 from "../../../assets/img/StartPage/2/3.png";
import img4 from "../../../assets/img/StartPage/2/4.png";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const Second = () => {
  const { t } = useTranslation();
  const mainRef = useRef(null);
  const cardRef_01 = useRef(null);
  const cardRef_02 = useRef(null);
  const cardRef_03 = useRef(null);
  const cardRef_04 = useRef(null);
  const underbRef = useRef(null);
  useEffect(() => {
    if (document.documentElement.clientWidth > 1220) {
      gsap.timeline().fromTo(
        underbRef.current,
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          delay: 0.5,
          scrollTrigger: {
            trigger: mainRef.current,
            start: "top bottom",
            end: "top center",
            scrub: 5,
            id: "scrub",
          },
        }
      );
      gsap.timeline().fromTo(
        cardRef_01.current,
        {
          y: 50,
        },
        {
          y: 0,
          duration: 1,
          delay: 0.5,
          scrollTrigger: {
            trigger: mainRef.current,
            start: "top bottom",
            end: "top center",
            scrub: 5,
            id: "scrub",
          },
        }
      );
      gsap.timeline().fromTo(
        cardRef_02.current,
        {
          y: 100,
        },
        {
          y: 0,
          duration: 1,
          delay: 0.5,
          scrollTrigger: {
            trigger: mainRef.current,
            start: "top bottom",
            end: "top center",
            scrub: 5,
            id: "scrub",
          },
        }
      );
      gsap.timeline().fromTo(
        cardRef_03.current,
        {
          y: 150,
        },
        {
          y: 0,
          duration: 1,
          delay: 0.5,
          scrollTrigger: {
            trigger: mainRef.current,
            start: "top bottom",
            end: "top center",
            scrub: 5,
            id: "scrub",
          },
        }
      );
      gsap.timeline().fromTo(
        cardRef_04.current,
        {
          y: 200,
        },
        {
          y: 0,
          duration: 1,
          delay: 0.5,
          scrollTrigger: {
            trigger: mainRef.current,
            start: "top bottom",
            end: "top center",
            scrub: 5,
            id: "scrub",
          },
        }
      );
    }
  }, []);
  return (
    <div className={[classes.second, ""].join(" ")} ref={mainRef}>
      <div className={[classes.secondBody, ""].join(" ")}>
        <div className="container">
          <h2
            className={[classes.secondTitle, "font-36"].join(" ")}
            ref={underbRef}
          >
            {t('start_features_of_title')}
          </h2>
          <div className={[classes.secondRow, ""].join(" ")}>
            <div
              className={[classes.secondRowColumn, ""].join(" ")}
              ref={cardRef_01}
            >
              <div className={[classes.secondRowColumnImg, ""].join(" ")}>
                <img src={img1} alt="" />
              </div>
              <p className={[classes.secondRowColumnText, "font-20"].join(" ")}>
                {t('start_features_of_card_01')}
              </p>
            </div>
            <div
              className={[classes.secondRowColumn, ""].join(" ")}
              ref={cardRef_02}
            >
              <div className={[classes.secondRowColumnImg, ""].join(" ")}>
                <img src={img2} alt="" />
              </div>
              <p className={[classes.secondRowColumnText, "font-20"].join(" ")}>
                {t('start_features_of_card_02')}
              </p>
            </div>
            <div
              className={[classes.secondRowColumn, ""].join(" ")}
              ref={cardRef_03}
            >
              <div className={[classes.secondRowColumnImg, ""].join(" ")}>
                <img src={img3} alt="" />
              </div>
              <p className={[classes.secondRowColumnText, "font-20"].join(" ")}>
                {t('start_features_of_card_03')}
              </p>
            </div>
            <div
              className={[classes.secondRowColumn, ""].join(" ")}
              ref={cardRef_04}
            >
              <div className={[classes.secondRowColumnImg, ""].join(" ")}>
                <img src={img4} alt="" />
              </div>
              <p className={[classes.secondRowColumnText, "font-20"].join(" ")}>
                {t('start_features_of_card_04')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Second;
