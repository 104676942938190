import React, { useEffect } from "react";
import First from "../../components/AboutPage/First/First";
import Second from "../../components/AboutPage/Second/Second";
import classes from "./AboutPage.module.scss";
const AboutPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={classes.about}>
      <div className={[classes.wrapBg, ""].join(" ")}>
        <First />
        <Second />
      </div>
    </div>
  );
};

export default AboutPage;
