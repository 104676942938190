import React, { useRef, useState, useEffect } from "react";
import classes from "./Three.module.scss";
import Button from "../../GlobalComponents/Button/Button";
import $ from "jquery";
import img1 from "../../../assets/img/StartPage/3/1.png";
import img2 from "../../../assets/img/StartPage/3/2.png";
import img4 from "../../../assets/img/StartPage/3/4.png";
import { useTranslation } from "react-i18next";

const Three = () => {
  const { t } = useTranslation();
  const spoiler1 = useRef(null);
  const spoiler2 = useRef(null);
  const spoiler3 = useRef(null);
  const spoiler4 = useRef(null);
  const [spoiler, setSpoiler] = useState(1);
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);
  useEffect(() => {
    $(spoiler2.current).slideUp(1);
    $(spoiler3.current).slideUp(1);
    $(spoiler4.current).slideUp(1);
  }, []);
  return (
    <div className={[classes.three, ""].join(" ")}>
      <div className={[classes.threeBody, ""].join(" ")}>
        <div className="container">
          <div
            className={
              spoiler !== 1
                ? [classes.threeSpoiler, "font-40"].join(" ")
                : [classes.threeSpoiler, classes.active, "font-40"].join(" ")
            }
          >
            <div
              className={
                spoiler !== 1
                  ? [classes.threeSpoilerTitle, "font-40"].join(" ")
                  : [classes.threeSpoilerTitle, classes.active, "font-40"].join(
                      " "
                    )
              }
              onClick={() => {
                setSpoiler(1);
                $(spoiler1.current).slideDown(600);
                $(spoiler2.current).slideUp(600);
                $(spoiler3.current).slideUp(600);
                $(spoiler4.current).slideUp(600);
              }}
            >
              {t('start_tab_01_title')}
            </div>
            <div
              className={[classes.threeSpoilerRow, ""].join(" ")}
              ref={spoiler1}
            >
              <div className={[classes.threeSpoilerRowLeft, ""].join(" ")}>
                <div
                  className={[classes.threeSpoilerRowLeftText, "font-20"].join(
                    " "
                  )}
                >
                  {t('start_tab_01_text_01')}
                </div>
                <div
                  className={[classes.threeSpoilerRowLeftText, "font-20"].join(
                    " "
                  )}
                >
                  {t('start_tab_01_text_02')}
                </div>
                <div
                  className={[classes.threeSpoilerRowLeftText, "font-20"].join(
                    " "
                  )}
                >
                  {t('start_tab_01_text_03')}
                </div>
                <div
                  className={[classes.threeSpoilerRowLeftText, "font-20"].join(
                    " "
                  )}
                >
                  {t('start_tab_01_text_04')}
                </div>
                {width > 1220 && (
                  <div
                    className={[classes.threeSpoilerRowLeftBtn, ""].join(" ")}
                  >
                    <Button>{t('btn_start_trading')}</Button>
                  </div>
                )}
              </div>
              <div className={[classes.threeSpoilerRowRight, ""].join(" ")}>
                <div
                  className={[classes.threeSpoilerRowRightWrapper, ""].join(
                    " "
                  )}
                >
                  <div
                    className={[classes.threeSpoilerRowRightImg, ""].join(" ")}
                  >
                    <img src={img1} alt="" />
                  </div>
                  <div
                    className={[
                      classes.threeSpoilerRowRightTitle,
                      "font-36",
                    ].join(" ")}
                  >
                    {t('start_tab_01_list_title')}
                  </div>
                  <div
                    className={[classes.threeSpoilerRowRightList, ""].join(" ")}
                  >
                    <div
                      className={[
                        classes.threeSpoilerRowRightItem,
                        "font-20",
                      ].join(" ")}
                    >
                      {t('start_tab_01_list_li_01')}
                    </div>
                    <div
                      className={[
                        classes.threeSpoilerRowRightItem,
                        "font-20",
                      ].join(" ")}
                    >
                      {t('start_tab_01_list_li_02')}
                    </div>
                    <div
                      className={[
                        classes.threeSpoilerRowRightItem,
                        "font-20",
                      ].join(" ")}
                    >
                      {t('start_tab_01_list_li_03')}
                    </div>
                    <div
                      className={[
                        classes.threeSpoilerRowRightItem,
                        "font-20",
                      ].join(" ")}
                    >
                      {t('start_tab_01_list_li_04')}
                    </div>
                    <div
                      className={[
                        classes.threeSpoilerRowRightItem,
                        "font-20",
                      ].join(" ")}
                    >
                      {t('start_tab_01_list_li_05')}
                    </div>
                  </div>
                  {width < 1221 && (
                    <div
                      className={[classes.threeSpoilerRowLeftBtn, ""].join(" ")}
                    >
                      <Button>{t('btn_start_trading')}</Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              spoiler !== 2
                ? [classes.threeSpoiler, "font-40"].join(" ")
                : [classes.threeSpoiler, classes.active, "font-40"].join(" ")
            }
          >
            <div
              className={
                spoiler !== 2
                  ? [classes.threeSpoilerTitle, "font-40"].join(" ")
                  : [classes.threeSpoilerTitle, classes.active, "font-40"].join(
                      " "
                    )
              }
              onClick={() => {
                setSpoiler(2);
                $(spoiler1.current).slideUp(600);
                $(spoiler2.current).slideDown(600);
                $(spoiler3.current).slideUp(600);
                $(spoiler4.current).slideUp(600);
              }}
            >
              {t('start_tab_02_title')}
            </div>
            <div
              className={[classes.threeSpoilerRow, ""].join(" ")}
              ref={spoiler2}
            >
              <div className={[classes.threeSpoilerRowLeft, ""].join(" ")}>
                <div
                  className={[classes.threeSpoilerRowLeftText, "font-20"].join(
                    " "
                  )}
                >
                  {t('start_tab_02_text_01')}
                </div>
                <div
                  className={[classes.threeSpoilerRowLeftText, "font-20"].join(
                    " "
                  )}
                >
                  {t('start_tab_02_text_02')}
                </div>
                <div
                  className={[classes.threeSpoilerRowLeftText, "font-20"].join(
                    " "
                  )}
                >
                  {t('start_tab_02_text_03')}
                </div>

                {width > 1220 && (
                  <div
                    className={[classes.threeSpoilerRowLeftBtn, ""].join(" ")}
                  >
                    <Button>{t('btn_start_trading')}</Button>
                  </div>
                )}
              </div>
              <div className={[classes.threeSpoilerRowRight, ""].join(" ")}>
                <div
                  className={[classes.threeSpoilerRowRightWrapper, ""].join(
                    " "
                  )}
                >
                  {width < 1221 && (
                    <div
                      className={[classes.threeSpoilerRowLeftBtn, ""].join(" ")}
                    >
                      <Button>{t('btn_start_trading')}</Button>
                    </div>
                  )}
                  <div
                    className={[classes.threeSpoilerRowRightImg, ""].join(" ")}
                  >
                    <img src={img2} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              spoiler !== 3
                ? [classes.threeSpoiler, "font-40"].join(" ")
                : [classes.threeSpoiler, classes.active, "font-40"].join(" ")
            }
          >
            <div
              className={
                spoiler !== 3
                  ? [classes.threeSpoilerTitle, "font-40"].join(" ")
                  : [classes.threeSpoilerTitle, classes.active, "font-40"].join(
                      " "
                    )
              }
              onClick={() => {
                setSpoiler(3);
                $(spoiler1.current).slideUp(600);
                $(spoiler2.current).slideUp(600);
                $(spoiler3.current).slideDown(600);
                $(spoiler4.current).slideUp(600);
              }}
            >
              {t('start_tab_03_title')}
            </div>
            <div
              className={[classes.threeSpoilerRow, ""].join(" ")}
              ref={spoiler3}
            >
              <div className={[classes.threeSpoilerRowLeft, ""].join(" ")}>
                <div
                  className={[classes.threeSpoilerRowLeftText, "font-20"].join(
                    " "
                  )}
                >
                  {t('start_tab_03_text_01')}
                </div>
                <div
                  className={[classes.threeSpoilerRowLeftText, "font-20"].join(
                    " "
                  )}
                >
                  {t('start_tab_03_text_02')}
                </div>
                <div
                  className={[classes.threeSpoilerRowLeftText, "font-20"].join(
                    " "
                  )}
                >
                  {t('start_tab_03_text_03')}
                </div>
                <div
                  className={[classes.threeSpoilerRowLeftText, "font-20"].join(
                    " "
                  )}
                >
                  {t('start_tab_03_text_04')}
                </div>
                <div
                  className={[classes.threeSpoilerRowLeftText, "font-20"].join(
                    " "
                  )}
                >
                  {t('start_tab_03_text_05')}
                </div>
                {width > 1220 && (
                  <div
                    className={[classes.threeSpoilerRowLeftBtn, ""].join(" ")}
                  >
                    <Button>{t('btn_start_trading')}</Button>
                  </div>
                )}
              </div>
              <div className={[classes.threeSpoilerRowRight, ""].join(" ")}>
                <div
                  className={[classes.threeSpoilerRowRightWrapper, ""].join(
                    " "
                  )}
                >
                  {width < 1221 && (
                    <div
                      className={[classes.threeSpoilerRowLeftBtn, ""].join(" ")}
                    >
                      <Button>{t('btn_start_trading')}</Button>
                    </div>
                  )}
                  <div
                    className={[classes.threeSpoilerRowRightImg, ""].join(" ")}
                  >
                    <img src={img1} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              spoiler !== 4
                ? [classes.threeSpoiler, "font-40"].join(" ")
                : [classes.threeSpoiler, classes.active, "font-40"].join(" ")
            }
          >
            <div
              className={
                spoiler !== 4
                  ? [classes.threeSpoilerTitle, "font-40"].join(" ")
                  : [classes.threeSpoilerTitle, classes.active, "font-40"].join(
                      " "
                    )
              }
              onClick={() => {
                setSpoiler(4);
                $(spoiler1.current).slideUp(600);
                $(spoiler2.current).slideUp(600);
                $(spoiler3.current).slideUp(600);
                $(spoiler4.current).slideDown(600);
              }}
            >
              {t('start_tab_05_title')}
            </div>
            <div
              className={[classes.threeSpoilerRow, ""].join(" ")}
              ref={spoiler4}
            >
              <div className={[classes.threeSpoilerRowLeft, ""].join(" ")}>
                <div
                  className={[classes.threeSpoilerRowLeftText, "font-20"].join(
                    " "
                  )}
                >
                  {t('start_tab_05_text_01')}
                </div>
                <div
                  className={[classes.threeSpoilerRowLeftText, "font-20"].join(
                    " "
                  )}
                >
                  {t('start_tab_05_text_02')}
                </div>
                <div
                  className={[classes.threeSpoilerRowLeftText, "font-20"].join(
                    " "
                  )}
                >
                  {t('start_tab_05_text_03')}
                </div>
                <div
                  className={[classes.threeSpoilerRowLeftText, "font-20"].join(
                    " "
                  )}
                >
                  {t('start_tab_05_text_04')}
                </div>
                <div
                  className={[classes.threeSpoilerRowLeftText, "font-20"].join(
                    " "
                  )}
                >
                  {t('start_tab_05_text_05')}
                </div>
                {width > 1220 && (
                  <div
                    className={[classes.threeSpoilerRowLeftBtn, ""].join(" ")}
                  >
                    <Button>{t('btn_start_trading')}</Button>
                  </div>
                )}
              </div>
              <div className={[classes.threeSpoilerRowRight, ""].join(" ")}>
                <div
                  className={[classes.threeSpoilerRowRightWrapper, ""].join(
                    " "
                  )}
                >
                  {width < 1221 && (
                    <div
                      className={[classes.threeSpoilerRowLeftBtn, ""].join(" ")}
                    >
                      <Button>{t('btn_start_trading')}</Button>
                    </div>
                  )}
                  <div
                    className={[classes.threeSpoilerRowRightImg, ""].join(" ")}
                  >
                    <img src={img4} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Three;
