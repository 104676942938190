import React, { useEffect } from "react";
import Banner from "../../components/MainPage/Banner/Banner";
import ChooseThe from "../../components/MainPage/ChooseThe/ChooseThe";
import DepositWidth from "../../components/MainPage/DepositWidth/DepositWidth";
import FeaturesOf from "../../components/MainPage/FeaturesOf/FeaturesOf";
import IndustryLeading from "../../components/MainPage/IndustryLeading/IndustryLeading";
import TradingPlatform from "../../components/MainPage/TradingPlatform/TradingPlatform";
import UnderBanner from "../../components/MainPage/UnderBanner/UnderBanner";

const MainPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="ovf-hidden">
            <Banner />
            <UnderBanner />
            <DepositWidth />
            <IndustryLeading />
            <FeaturesOf />
            <ChooseThe />
            <TradingPlatform />
        </div>
    );
};

export default MainPage;
