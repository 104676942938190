import React, { useEffect, useRef } from "react";
import Button from "../../GlobalComponents/Button/Button";
import classes from "./Four.module.scss";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Four = () => {
  const { t } = useTranslation();
  const mainRef = useRef(null);
  const cardRef_01 = useRef(null);
  useEffect(() => {
    if (window.innerWidth > 1220) {
      setTimeout(() => {
        gsap.timeline().fromTo(
          cardRef_01.current,
          {
            y: 100,
            x: 1000,
            scale: 0.3,
          },
          {
            y: 0,
            x: 0,
            scale: 1,
            duration: 1,
            delay: 0.5,
            scrollTrigger: {
              trigger: mainRef.current,
              start: "top bottom",
              end: "top center",
              scrub: 5,
              id: "scrub",
            },
          }
        );
      }, 10);
    }
  }, []);
  return (
    <div className={[classes.four, ""].join(" ")} ref={mainRef}>
      <div className={[classes.fourBody, ""].join(" ")}>
        <div className={[classes.fourGirl, ""].join(" ")}></div>
        <div
          className={[classes.fourMoney, ""].join(" ")}
          ref={cardRef_01}
        ></div>

        <div className="container">
          <div className={[classes.fourTitle, "font-64"].join(" ")}>
            {t('get_bonuses_title')}
          </div>
          <div className={[classes.fourWelcomeTitle, "font-48"].join(" ")}>
            {t('get_bonuses_subtitle')}
          </div>
          <div className={[classes.fourWelcomeSubtitle, "font-32"].join(" ")}>
            {t('get_bonuses_text')}
          </div>
          <div className={[classes.fourWelcomeText, "font-20"].join(" ")}>
            {t('get_bonuses_list_title')}
          </div>
          <div className={[classes.fourWelcomeList, ""].join(" ")}>
            <div className={[classes.fourWelcomeListItem, "font-20"].join(" ")}>
              {t('get_bonuses_list_li_01')}
            </div>
            <div className={[classes.fourWelcomeListItem, "font-20"].join(" ")}>
              {t('get_bonuses_list_li_02')}
            </div>
            <div className={[classes.fourWelcomeListItem, "font-20"].join(" ")}>
              {t('get_bonuses_list_li_03')}
            </div>
          </div>
          <div className={[classes.fourBtn, ""].join(" ")}>
            <Button>{t('btn_open_account')}</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Four;
