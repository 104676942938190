import React from "react";
import classes from './Overall.module.scss';
import image from '../../../assets/img/SecurityPage/Overall/img.png';
import info_icon from '../../../assets/img/SecurityPage/Overall/info_icon.svg';
import { useTranslation } from "react-i18next";

const Overall = () => {
    const { t } = useTranslation();
    return (
        <section className={classes.overall}>
            <img className={classes.image} src={image} alt=''/>
            <div className="container">
                <div className={classes.overallBody}>
                    <img className={classes.infoIcon} src={info_icon} alt=''/>
                    <h4 className={[classes.text, 'font-32'].join(' ')}>
                        {t('overall_title')}
                    </h4>
                    <div className={classes.buttons}>
                        <a href="/docs/PRIVACY_POLICY.pdf" target="_blank" className={classes.btn}>
                            {t('overall_btn_01')}
                        </a>
                        {/* <div className={classes.btn}>
                            {t('overall_btn_02')}
                        </div> */}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Overall;
