import React, { useEffect, useRef } from "react";
import classes from './UnderBanner.module.scss';
import icon_01 from '../../../assets/img/MainPage/UnderBanner/icon_01.svg';
import icon_02 from '../../../assets/img/MainPage/UnderBanner/icon_02.svg';
import icon_03 from '../../../assets/img/MainPage/UnderBanner/icon_03.svg';
import icon_04 from '../../../assets/img/MainPage/UnderBanner/icon_04.svg';
import gsap from "gsap";
import { useTranslation } from "react-i18next";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const UnderBanner = () => {
    const { t } = useTranslation();
    const cardRef_01 = useRef(null);
    const cardRef_02 = useRef(null);
    const cardRef_03 = useRef(null);
    const cardRef_04 = useRef(null);
    const underbRef = useRef(null);
    const cards = [
        {
            ref: cardRef_01,
            icon: icon_01,
            title: 'underbanner_card_01_title',
            text: 'underbanner_card_01_text',
        },
        {
            ref: cardRef_02,
            icon: icon_02,
            title: 'underbanner_card_02_title',
            text: 'underbanner_card_02_text',
        },
        {
            ref: cardRef_03,
            icon: icon_03,
            title: 'underbanner_card_03_title',
            text: 'underbanner_card_03_text',
        },
        {
            ref: cardRef_04,
            icon: icon_04,
            title: 'underbanner_card_04_title',
            text: 'underbanner_card_04_text',
        },
    ];
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(underbRef.current, {
                opacity: 0,
                y: 50,
            }, {
                opacity: 1,
                y: 0,
                duration: 1,
                delay: .5,
            });
            gsap.timeline().fromTo(cardRef_01.current, {
                y: 50,
            }, {
                y: 0,
                duration: 1,
                delay: .5,
            });
            gsap.timeline().fromTo(cardRef_02.current, {
                y: 100,
            }, {
                y: 0,
                duration: 1,
                delay: .5,
            });
            gsap.timeline().fromTo(cardRef_03.current, {
                y: 150,
            }, {
                y: 0,
                duration: 1,
                delay: .5,
            });
            gsap.timeline().fromTo(cardRef_04.current, {
                y: 200,
            }, {
                y: 0,
                duration: 1,
                delay: .5,
            });
        }
    }, []);
    return (
        <section className="container">
            <div ref={underbRef} className={classes.underBanner}>
                <h3 className={[classes.title, 'font-36'].join(' ')}>
                    {t('underbanner_title')}
                </h3>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <div ref={card.ref} key={index} className={classes.card}>
                            <img src={card.icon} className={classes.cardIcon} alt=''/>
                            <h4 className={classes.cardTitle}>
                                {t(card.title)}
                            </h4>
                            <p className={classes.cardText}>
                                {t(card.text)}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default UnderBanner;
