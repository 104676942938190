import React, { useRef, useEffect } from "react";
import classes from './Banner.module.scss';
import Button from '../../GlobalComponents/Button/Button';
import { useTranslation } from "react-i18next";
import icon_01 from '../../../assets/img/SecurityPage/Banner/icon_01.png';
import icon_02 from '../../../assets/img/SecurityPage/Banner/icon_02.png';
import icon_03 from '../../../assets/img/SecurityPage/Banner/icon_03.png';
import icon_04 from '../../../assets/img/SecurityPage/Banner/icon_04.png';
import icon_05 from '../../../assets/img/SecurityPage/Banner/icon_05.png';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Banner = () => {
    const { t } = useTranslation();
    const cardRef_01 = useRef(null);
    const cardRef_02 = useRef(null);
    const cardRef_03 = useRef(null);
    const cardRef_04 = useRef(null);
    const cardRef_05 = useRef(null);
    const cardsRef = useRef(null);
    const headersRef = useRef(null);
    const cards = [
        {
            ref: cardRef_01,
            icon: icon_01,
            title: 'security_banner_li_01_title',
            text: 'security_banner_li_01_text',
        },
        {
            ref: cardRef_02,
            icon: icon_02,
            title: 'security_banner_li_02_title',
            text: 'security_banner_li_02_text',
        },
        {
            ref: cardRef_03,
            icon: icon_03,
            title: 'security_banner_li_03_title',
            text: 'security_banner_li_03_text',
        },
        {
            ref: cardRef_04,
            icon: icon_04,
            title: 'security_banner_li_04_title',
            text: 'security_banner_li_04_text',
        },
        {
            ref: cardRef_05,
            icon: icon_05,
            title: 'security_banner_li_05_title',
            text: 'security_banner_li_05_text',
        },
    ];
    useEffect(() => {
        gsap.fromTo(headersRef.current, {
            opacity: 0,
            y: 150, 
        }, {
            opacity: 1,
            y: 0,
            delay: .5,
            duration: 1,
        });
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(cardRef_01.current, {
                y: 50,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: cardsRef.current,
                    end: "+=300px",
                    scrub: 3,
                }
            });
            gsap.timeline().fromTo(cardRef_02.current, {
                y: 100,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: cardsRef.current,
                    end: "+=300px",
                    scrub: 3,
                }
            });
            gsap.timeline().fromTo(cardRef_03.current, {
                y: 150,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: cardsRef.current,
                    end: "+=300px",
                    scrub: 3,
                }
            });
            gsap.timeline().fromTo(cardRef_04.current, {
                y: 200,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: cardsRef.current,
                    end: "+=500px",
                    scrub: 3,
                }
            });
            gsap.timeline().fromTo(cardRef_05.current, {
                y: 250,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: cardsRef.current,
                    end: "+=500px",
                    scrub: 3,
                }
            });
        }
    }, []);
    return (
        <section className={classes.banner}>
            <div className="container">
                <div ref={headersRef} className={classes.bannerBody}>
                    <div>
                        <h1 className={[classes.title, 'font-64'].join(' ')}>
                            {t('security_banner_title')}
                        </h1>
                        <h2 className={[classes.subtitle, 'font-36'].join(' ')}>
                            {t('how_we_keep_title')}
                        </h2>
                        <p className={classes.text}>
                            {t('how_we_keep_text')}
                        </p>
                        <div className={classes.btn}>
                            <Button>{t('btn_start_trading')}</Button>
                        </div>
                    </div>
                    <h2 className={[classes.securityTitle, 'font-48'].join(' ')}>
                        {t('security_banner_subtitle')}
                    </h2>
                    <p className={classes.securityText}>
                        {t('security_banner_text')}
                    </p>
                    <div ref={cardsRef} className={classes.cards}>
                        {cards.map((card, index) =>
                            <div ref={card.ref} key={index} className={classes.card}>
                                <img className={classes.cardIcon} src={card.icon} alt=''/>
                                <h4 className={classes.cardTitle}>
                                    {t(card.title)}
                                </h4>
                                <p className={classes.cardText}>
                                    {t(card.text)}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Banner;
