import React, { useEffect, useRef } from "react";
import classes from "./First.module.scss";
import gsap from "gsap";
import { useTranslation } from "react-i18next";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const First = () => {
  const { t } = useTranslation();
  const bannerBodyRef = useRef(null);
  useEffect(() => {
    gsap.fromTo(
      bannerBodyRef.current,
      {
        opacity: 0,
        y: 150,
      },
      {
        opacity: 1,
        y: 0,
        delay: 0.5,
        duration: 1,
      }
    );
  }, []);
  return (
    <div className={[classes.first, ""].join(" ")}>
      <div className={[classes.firstBody, ""].join(" ")}>
        <div className={[classes.firstImg, ""].join(" ")}></div>

        <div
          className={["container", classes.cont].join(" ")}
          ref={bannerBodyRef}
        >
          <h1 className={[classes.firstTitle, "font-64"].join(" ")}>
            {t('start_banner_title')}
          </h1>
          <h2 className={[classes.firstSubtitle, "font-48"].join(" ")}>
            {t('start_banner_subtitle')}
          </h2>
          <p className={[classes.titleList, "font-36"].join(" ")}>
            {t('start_banner_list_title')}
          </p>
          <ul className={[classes.firstList, ""].join(" ")}>
            <li className={[classes.firstListItem, "font-24"].join(" ")}>
              {t('start_banner_li_01')}
            </li>
            <li className={[classes.firstListItem, "font-24"].join(" ")}>
              {t('start_banner_li_02')}
            </li>
            <li className={[classes.firstListItem, "font-24"].join(" ")}>
              {t('start_banner_li_03')}
            </li>
            <li className={[classes.firstListItem, "font-24"].join(" ")}>
              {t('start_banner_li_04')}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default First;
