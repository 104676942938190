import React, { useEffect, useRef } from "react";
import classes from "./First.module.scss";
import Button from "../../GlobalComponents/Button/Button";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const First = () => {
  const { t } = useTranslation();
  const bannerBodyRef = useRef(null);
  useEffect(() => {
    gsap.fromTo(
      bannerBodyRef.current,
      {
        opacity: 0,
        y: 150,
      },
      {
        opacity: 1,
        y: 0,
        delay: 0.5,
        duration: 1,
      }
    );
  }, []);
  return (
    <div className={[classes.first, ""].join(" ")}>
      <div className={[classes.firstBody, ""].join(" ")}>
        <div className="container" ref={bannerBodyRef}>
          <div className={[classes.firstTitle, "font-48"].join(" ")}>
            {t('about_banner_title')}
          </div>
          <div
            className={[classes.firstText, classes.mb40, "font-20"].join(" ")}
          >
            {t('about_banner_text_01')}
          </div>
          <div
            className={[classes.firstText, classes.mb40, "font-20"].join(" ")}
          >
            {t('about_banner_text_02')}
          </div>
          <div
            className={[classes.firstText, classes.mb40, "font-20"].join(" ")}
          >
            {t('about_banner_text_03')}
          </div>
          <div
            className={[classes.firstText, classes.mb40, "font-20"].join(" ")}
          >
            {t('about_banner_text_04')}
          </div>
          <div
            className={[classes.firstText, classes.mb40, "font-20"].join(" ")}
          >
            {t('about_banner_text_05')}
          </div>
          <div className={[classes.firstBtn, ""].join(" ")}>
            <Button>{t('btn_start_trading')}</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default First;
