import React, { useEffect, useRef } from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Banner = () => {
    const { t } = useTranslation();
    const bodyRef = useRef(null);
    useEffect(() => {
        gsap.fromTo(bodyRef.current, {
            opacity: 0,
            y: 150,
        }, {
            opacity: 1,
            y: 0,
            delay: 0.5,
            duration: 1,
        });
    }, []);
    return (
        <section className={classes.banner}>
            <div className="container">
                <div ref={bodyRef} className={classes.bannerBody}>
                    <h1 className={[classes.title, 'font-64'].join(' ')}>
                        {t('trading_acc_banner_title')}
                    </h1>
                    <h2 className={classes.subtitle}>
                        {t('trading_acc_banner_subtitle')}
                    </h2>
                    <h3 className={[classes.subtitle_01, 'font-32'].join(' ')}>
                        {t('trading_acc_banner_subtitle_01')}
                    </h3>
                    <div className={classes.text}>
                        <p>{t('trading_acc_banner_text_01')}</p>
                    </div>
                    <h3 className={[classes.subtitle_02, 'font-32'].join(' ')}>
                        {t('trading_acc_banner_subtitle_02')}
                    </h3>
                    <div className={classes.text}>
                        <p>{t('trading_acc_banner_text_02')}</p>
                        <p>{t('trading_acc_banner_text_03')}</p>
                    </div>
                    <h3 className={[classes.subtitle_03, 'font-32'].join(' ')}>
                        {t('trading_acc_banner_list_title')}
                    </h3>
                    <ul>
                        <li>{t('trading_acc_banner_list_li_01')}</li>
                        <li>{t('trading_acc_banner_list_li_02')}</li>
                        <li>{t('trading_acc_banner_list_li_03')}</li>
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default Banner;
