import React, { useEffect } from "react";
import Banner from "../../components/TradingAccountsPage/Banner/Banner";
import DifferenceBetween from "../../components/TradingAccountsPage/DifferenceBetween/DifferenceBetween";
import FeelConfident from "../../components/TradingAccountsPage/FeelConfident/FeelConfident";

const TradingAccountsPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="ovf-hidden">
            <Banner />
            <FeelConfident />
            <DifferenceBetween />
        </div>
    );
};

export default TradingAccountsPage;
