import React, { useEffect, useRef, useState } from "react";
import classes from './Faq.module.scss';
import { useTranslation } from "react-i18next";
import $ from 'jquery';

const Faq = () => {
    const { t } = useTranslation();
    const tab_01_ref = useRef(null);
    const tab_02_ref = useRef(null);
    const tab_03_ref = useRef(null);
    const [ tab01Active, setTab01Active ] = useState(false);
    const [ tab02Active, setTab02Active ] = useState(false);
    const [ tab03Active, setTab03Active ] = useState(false);
    useEffect(() => {
        $(tab_01_ref.current).slideUp(300);
        $(tab_02_ref.current).slideUp(300);
        $(tab_03_ref.current).slideUp(300);
    }, []);
    return (
        <section className="container">
            <div className={classes.faq}>
                <h2 className={classes.title}>
                    {t('security_faq_title')}
                </h2>
                <div className={classes.tabs}>
                    <div 
                        className={classes.tab}
                        onClick={() => {
                            $(tab_01_ref.current).slideToggle(300);
                            setTab01Active(value => !value);
                        }}    
                    >
                        <div className={classes.tabTitle}>
                            <h3 className={[classes.tabTitleText, 'text-default'].join(' ')}>
                                {t('security_faq_01_title')}
                            </h3>
                            <div className={`${classes.tabTitleStatus} ${tab01Active && classes.activeStatus}`}></div>
                        </div>
                        <p ref={tab_01_ref} className={classes.tabContent}>
                            {t('security_faq_01_text')}
                        </p>
                    </div>
                    <div 
                        className={classes.tab}
                        onClick={() => {
                            $(tab_02_ref.current).slideToggle(300);
                            setTab02Active(value => !value);
                        }}    
                    >
                        <div className={classes.tabTitle}>
                            <h3 className={[classes.tabTitleText, 'text-default'].join(' ')}>
                                {t('security_faq_02_title')}
                            </h3>
                            <div className={`${classes.tabTitleStatus} ${tab02Active && classes.activeStatus}`}></div>
                        </div>
                        <p ref={tab_02_ref} className={classes.tabContent}>
                            {t('security_faq_02_text')}
                        </p>
                    </div>
                    <div 
                        className={classes.tab}
                        onClick={() => {
                            $(tab_03_ref.current).slideToggle(300);
                            setTab03Active(value => !value);
                        }}    
                    >
                        <div className={classes.tabTitle}>
                            <h3 className={[classes.tabTitleText, 'text-default'].join(' ')}>
                                {t('security_faq_03_title')}
                            </h3>
                            <div className={`${classes.tabTitleStatus} ${tab03Active && classes.activeStatus}`}></div>
                        </div>
                        <p ref={tab_03_ref} className={classes.tabContent}>
                            {t('security_faq_03_text')}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Faq;
