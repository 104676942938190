import React, { useEffect, useRef } from "react";
import classes from './ChooseThe.module.scss';
import { Trans, useTranslation } from "react-i18next";
import icon_01 from '../../../assets/img/MainPage/ChooseThe/icon_01.png';
import icon_02 from '../../../assets/img/MainPage/ChooseThe/icon_02.png';
import icon_03 from '../../../assets/img/MainPage/ChooseThe/icon_03.png';
import icon_04 from '../../../assets/img/MainPage/ChooseThe/icon_04.png';
import { Link } from "react-router-dom";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const ChooseThe = () => {
    const { t } = useTranslation();
    const cardRef_01 = useRef(null);
    const cardRef_02 = useRef(null);
    const cardRef_03 = useRef(null);
    const cardRef_04 = useRef(null);
    const chooseTheRef = useRef(null);
    const cards = [
        {
            icon: icon_01,
            text: 'shoose_the_card_01',
            ref: cardRef_01,
        },
        {
            icon: icon_02,
            text: 'shoose_the_card_02',
            ref: cardRef_02,
        },
        {
            icon: icon_03,
            text: 'shoose_the_card_03',
            ref: cardRef_03,
        },
        {
            icon: icon_04,
            text: 'shoose_the_card_04',
            ref: cardRef_04,
        },
    ];
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(cardRef_01.current, {
                y: 50,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: chooseTheRef.current,
                    end: "+=400px",
                    scrub: 3,
                }
            });
            gsap.timeline().fromTo(cardRef_02.current, {
                y: 100,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: chooseTheRef.current,
                    end: "+=400px",
                    scrub: 3,
                }
            });
            gsap.timeline().fromTo(cardRef_03.current, {
                y: 150,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: chooseTheRef.current,
                    end: "+=400px",
                    scrub: 3,
                }
            });
            gsap.timeline().fromTo(cardRef_04.current, {
                y: 200,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: chooseTheRef.current,
                    end: "+=400px",
                    scrub: 3,
                }
            });
        }
    }, []);
    return (
        <section className="container">
            <div className={classes.chooseThe}>
                <h2 className={classes.title}>
                    <Trans>
                        {t('shoose_the_title')}
                    </Trans>
                </h2>
                <h3 className={[classes.subtitle, 'font-32'].join(' ')}>
                    {t('shoose_the_subtitle')}
                </h3>
                <div className={classes.cards} ref={chooseTheRef}>
                    {cards.map((card, index) =>
                        <div ref={card.ref} key={index} className={classes.card}>
                            <img className={classes.cardIcon} src={card.icon} alt={card.text}/>
                            <p className={classes.cardText}>
                                {t(card.text)}
                            </p>
                            <Link to='/trading' className={classes.cardBtn}>
                                {t('btn_see_more')}
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default ChooseThe;
