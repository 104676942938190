import React, { useEffect } from "react";
import First from "../../components/EducationComponents/First/First";
import Second from "../../components/EducationComponents/Second/Second";
import Most from "../../components/EducationComponents/Most/Most";
import classes from "./EducationPage.module.scss";
const EducationPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={classes.education}>
      <First />
      <Second />
      <Most />
    </div>
  );
};

export default EducationPage;
