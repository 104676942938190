import React, { useEffect, useRef } from "react";
import Button from "../../GlobalComponents/Button/Button";
import classes from './DepositWidth.module.scss';
import table_img_01 from '../../../assets/img/MainPage/DepositWith/table_img_01.png';
import table_img_02 from '../../../assets/img/MainPage/DepositWith/table_img_02.png';
import table_img_03 from '../../../assets/img/MainPage/DepositWith/table_img_03.png';
import table_img_04 from '../../../assets/img/MainPage/DepositWith/table_img_04.png';
import phone from '../../../assets/img/MainPage/DepositWith/phone.png';
import phone_mob from '../../../assets/img/MainPage/DepositWith/phone_mob.png';
import coin_01 from '../../../assets/img/MainPage/DepositWith/coin_01.png';
import coin_02 from '../../../assets/img/MainPage/DepositWith/coin_02.png';
import coin_03 from '../../../assets/img/MainPage/DepositWith/coin_03.png';
import coin_04 from '../../../assets/img/MainPage/DepositWith/coin_04.png';
import { Trans, useTranslation } from "react-i18next";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const rows = [
    {
        image: table_img_01,
        col_02: 'usd, eur',
        col_03: 'deposit_with_table_instantly',
    },
    {
        image: table_img_02,
        col_02: 'eur, usd',
        col_03: 'deposit_with_table_instantly',
    },
    {
        image: table_img_03,
        col_02: 'usd',
        col_03: 'deposit_with_table_instantly',
    },
    {
        image: table_img_04,
        col_02: 'usd, eur',
        col_03: 'deposit_with_commission',
    },
];

const DepositWidth = () => {
    const { t } = useTranslation();
    const coinRef_01 = useRef(null);
    const coinRef_02 = useRef(null);
    const coinRef_03 = useRef(null);
    const coinRef_04 = useRef(null);
    const depositWidthRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().to(coinRef_01.current, {
                scale: 1,
                x: 0,
                y: 0,
                scrollTrigger: {
                    trigger: depositWidthRef.current,
                    end: "+=500px",
                    scrub: 3,
                }
            });
            gsap.timeline().to(coinRef_02.current, {
                scale: 1,
                x: 0,
                y: 0,
                scrollTrigger: {
                    trigger: depositWidthRef.current,
                    end: "+=500px",
                    scrub: 3,
                }
            });
            gsap.timeline().to(coinRef_03.current, {
                scale: 1,
                x: 0,
                y: 0,
                scrollTrigger: {
                    trigger: depositWidthRef.current,
                    end: "+=500px",
                    scrub: 3,
                }
            });
            gsap.timeline().to(coinRef_04.current, {
                scale: 1,
                x: 0,
                y: 0,
                scrollTrigger: {
                    trigger: depositWidthRef.current,
                    end: "+=500px",
                    scrub: 3,
                }
            });
        }
    }, []);
    return (
        <section className="container">
            <div ref={depositWidthRef} className={classes.depositWidth}>
                <h2 className={[classes.title, 'font-48'].join(' ')}>
                    <Trans>
                        {t('deposit_with_title')}
                    </Trans>
                </h2>
                <div className={classes.tableWr}>
                    <table>
                        <tbody>
                            {rows.map((row, index) =>
                                <tr key={index}>
                                    <td>
                                        <img src={row.image} alt=''/>
                                    </td>
                                    <td>
                                        {t(row.col_02)}
                                    </td>
                                    <td>
                                        <Trans>
                                            {t(row.col_03)}
                                        </Trans>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className={classes.btn}>
                    <Button>{t('btn_open_account')}</Button>
                </div>
                <img className={classes.phone} src={phone} alt=''/>
                <img ref={coinRef_01} className={classes.coin_01} src={coin_01} alt=''/>
                <img ref={coinRef_02} className={classes.coin_02} src={coin_02} alt=''/>
                <img ref={coinRef_03} className={classes.coin_03} src={coin_03} alt=''/>
                <img ref={coinRef_04} className={classes.coin_04} src={coin_04} alt=''/>
                <img className={classes.phone_mob} src={phone_mob} alt=''/>
            </div>
        </section>
    );
};

export default DepositWidth;
