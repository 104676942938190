import React from "react";
import { Trans, useTranslation } from "react-i18next";
import classes from './DifferenceBetween.module.scss';

const rows = [
    [
        'Account comparison',
        'feel_confident_card_01_title',
        'feel_confident_card_02_title',
        'feel_confident_card_03_title',
        'feel_confident_card_04_title',
    ],
    [
        'difference_between_ROW_02_COL_01',
        'difference_between_ROW_02_COL_02',
        'difference_between_ROW_02_COL_03',
        'difference_between_ROW_02_COL_04',
        'difference_between_ROW_02_COL_05',
    ],
    [
        'difference_between_ROW_03_COL_01',
        'difference_between_ROW_03_COL_02',
        'difference_between_ROW_03_COL_03',
        'difference_between_ROW_03_COL_04',
        'difference_between_ROW_03_COL_05',
    ],
    [
        'feel_confident_table_row_02',
        '0.3 $',
        '1.1 $',
        '0.1 $',
        '0.7 $',
    ],
    [
        'feel_confident_table_row_01',
        'difference_between_ROW_05_COL_02',
        'difference_between_ROW_05_COL_03',
        'difference_between_ROW_05_COL_04',
        'difference_between_ROW_05_COL_05',
    ],
    [
        'difference_between_ROW_06_COL_01',
        '200',
        '200',
        '200',
        '200',
    ],
    [
        'difference_between_ROW_07_COL_01',
        'difference_between_ROW_07_COL_02',
        'difference_between_ROW_07_COL_03',
        'difference_between_ROW_07_COL_02',
        'difference_between_ROW_07_COL_02',
    ],
];

const DifferenceBetween = () => {
    const { t } = useTranslation();
    return (
        <section className={classes.differenceBetween}>
            <div className="container">
                <h2 className={[classes.title, 'font-48'].join(' ')}>
                    {t('difference_between_title')}
                </h2>
            </div>
            <div className={["container", classes.container].join(' ')}>
                <div className={classes.tableWr}>
                    <table>
                        <tbody>
                            {rows.map((row, rowIndex) =>
                                <tr key={rowIndex}>
                                    {row.map((col, colIndex) =>
                                        <td key={colIndex}>
                                            <Trans>
                                                {t(col)}
                                            </Trans>
                                        </td>
                                    )}
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    );
};

export default DifferenceBetween;
