import MainPage from '../pages/MainPage/MainPage';
import StartPage from '../pages/StartPage/StartPage';
import SecurityPage from '../pages/SecurityPage/SecurityPage';
import TradingAccountsPage from '../pages/TradingAccountsPage/TradingAccountsPage';
import EducationPage from '../pages/EducationPage/EducationPage';
import AboutPage from '../pages/AboutPage/AboutPage';

export const routes = [
    { path: '/', element: MainPage },
    { path: '/start', element: StartPage },
    { path: '/security', element: SecurityPage },
    { path: '/trading', element: TradingAccountsPage },
    { path: '/education', element: EducationPage },
    { path: '/about', element: AboutPage },
];

export const headerRoutes = [
    { id: 1, path: '/start', text: 'header_start' },
    { id: 2, path: '/security', text: 'header_security' },
    { id: 3, path: '/trading', text: 'header_trading_accounts' },
    { id: 4, path: '/education', text: 'header_education' },
    { id: 5, path: '/about', text: 'header_about_us' },
];
