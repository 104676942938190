import React, { useEffect } from "react";
import First from "../../components/StartPage/First/First";
import Four from "../../components/StartPage/Four/Four";
import Second from "../../components/StartPage/Second/Second";
import Three from "../../components/StartPage/Three/Three";
import classes from "./StartPage.module.scss";
const StartPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={classes.start}>
      <First />
      <Second />
      <Three />
      <Four />
    </div>
  );
};

export default StartPage;
