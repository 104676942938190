import React, { useEffect } from "react";
import Banner from "../../components/SecurityPage/Banner/Banner";
import Faq from "../../components/SecurityPage/Faq/Faq";
import Overall from "../../components/SecurityPage/Overall/Overall";

const SecurityPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="ovf-hidden">
            <Banner />
            <Faq />
            <Overall />
        </div>
    );
};

export default SecurityPage;
