import React, { useEffect, useRef } from "react";
import classes from "./Second.module.scss";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Second = () => {
  const { t } = useTranslation();
  const bannerBodyRef = useRef(null);
  const mainRef = useRef(null);
  useEffect(() => {
    gsap.fromTo(
      bannerBodyRef.current,
      {
        opacity: 0,
        y: 150,
      },
      {
        opacity: 1,
        y: 0,
        delay: 0.5,
        duration: 1,
        scrollTrigger: {
          trigger: mainRef.current,
          start: "top bottom",
          end: "top center",
          scrub: 5,
          id: "scrub",
        },
      }
    );
  }, []);
  return (
    <div className={[classes.first, ""].join(" ")} ref={mainRef}>
      <div className={[classes.firstBody, ""].join(" ")}>
        <div className="container" ref={bannerBodyRef}>
          <div className={[classes.firstOver, ""].join(" ")}>
            <div className={[classes.firstWrap, ""].join(" ")}>
              <div className={[classes.firstTitle, "font-48"].join(" ")}>
                {t('our_team_title')}
              </div>
              <div
                className={[classes.firstText, classes.mb40, "font-20"].join(
                  " "
                )}
              >
                {t('our_team_text_01')}
              </div>
              <div
                className={[classes.firstText, classes.mb40, "font-20"].join(
                  " "
                )}
              >
                {t('our_team_text_02')}
              </div>
              <div
                className={[classes.firstText, classes.mb40, "font-20"].join(
                  " "
                )}
              >
                {t('our_team_text_03')}
              </div>
              <div
                className={[classes.firstText, classes.mb40, "font-20"].join(
                  " "
                )}
              >
                {t('our_team_text_04')}
              </div>
              <div className={[classes.firstText, "font-20"].join(" ")}>
                {t('our_team_text_05')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Second;
